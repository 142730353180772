<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-500 text-transparent bg-clip-text">
            Cadastrar Empresa
        </h1>
        <br />
        <button v-if="isAdmin && !showForm" @click="showForm = true"
            class="mb-4 w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
            Cadastrar Nova Empresa
        </button>

        <form @submit.prevent="handleSubmit" v-if="isAdmin && showForm" class="space-y-6">
            <div>
                <label for="nome" class="block text-sm font-medium text-gray-700">Nome da Empresa</label>
                <input id="nome" v-model="nome" type="text" required
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
            <div>
                <label for="userId" class="block text-sm font-medium text-gray-700">Usuário</label>
                <select id="userId" v-model="userId" required
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <option value="" disabled>Selecione um usuário</option>
                    <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                </select>
            </div>
            <div v-if="!isEditing">
                <label for="novoNumero" class="block text-sm font-medium text-gray-700">Número de Telefone</label>
                <input id="novoNumero" v-model="novoNumero" type="text"
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                <button type="button" @click="addNumero"
                    class="mt-2 w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 font-semibold">
                    Adicionar Número
                </button>
            </div>
            <ul v-if="!isEditing" class="space-y-2">
                <li v-for="(numero, index) in numeros" :key="index"
                    class="bg-gray-100 p-2 rounded-lg flex justify-between items-center">
                    <span>{{ numero }}</span>
                    <button @click="removeNumero(index)" class="text-red-600 hover:underline">Remover</button>
                </li>
            </ul>
            <div v-for="(pergunta, index) in perguntasFixas" :key="index" class="mb-5">
                <label :for="'texto' + index" class="block text-sm font-medium text-gray-700">Texto da Pergunta
                    Fixa</label>
                <input :id="'texto' + index" v-model="pergunta.texto" type="text"
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                <label :for="'resposta' + index" class="block text-sm font-medium text-gray-700 mt-2">Resposta</label>
                <textarea :id="'resposta' + index" v-model="pergunta.resposta"
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"></textarea>
            </div>
            <button type="submit"
                class="w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-300 font-semibold">
                {{ isEditing ? 'Atualizar Empresa' : 'Cadastrar Empresa' }}
            </button>
        </form>

        <div v-if="isAdmin && empresas.length > 0" class="mt-8">
            <h2 class="text-2xl font-bold mb-4 text-gray-800">Empresas</h2>
            <div class="mb-5">
                <label for="search" class="block text-sm font-medium text-gray-700">Pesquisar Empresa</label>
                <input id="search" v-model="searchTerm" type="text"
                    class="mt-1 p-3 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
            <ul class="space-y-2">
                <li v-for="empresa in filteredEmpresas" :key="empresa.id"
                    class="p-4 bg-gray-100 rounded-lg shadow-sm flex justify-between items-center">
                    <div>
                        <span class="font-medium text-gray-800">{{ empresa.nome }}</span>
                    </div>
                    <div class="flex space-x-2">
                        <button @click="editEmpresa(empresa)" class="text-blue-600 hover:underline">Editar</button>
                        <button @click="confirmDeleteEmpresa(empresa.id)"
                            class="text-red-600 hover:underline">Deletar</button>
                    </div>
                </li>
            </ul>
        </div>
        <p v-if="statusMessage" class="mt-4 text-center text-gray-700 font-medium">{{ statusMessage }}</p>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const nome = ref('');
const userId = ref('');
const novoNumero = ref('');
const numeros = ref([]);
const statusMessage = ref('');
const userRole = ref('');
const isAdmin = ref(false);
const empresas = ref([]);
const users = ref([]);
const searchTerm = ref('');
const isEditing = ref(false);
const editingEmpresaId = ref('');
const showForm = ref(false);
const perguntasFixas = ref([]);
const router = useRouter();

const checkUserRole = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            const response = await axios.get('https://web-production-8fe56.up.railway.app/auth/me', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            userRole.value = response.data.role;
            isAdmin.value = userRole.value === 'ADMIN';
            if (!isAdmin.value) {
                statusMessage.value = 'Você não tem permissão para cadastrar empresas.';
                setTimeout(() => {
                    router.push('/');
                }, 2000);
            } else {
                fetchEmpresas(user.token);
                fetchUsers(user.token);
                fetchPerguntasFixas(user.token);
            }
        } else {
            statusMessage.value = 'Você precisa estar logado.';
            setTimeout(() => {
                router.push('/');
            }, 2000);
        }
    } catch (error) {
        console.error('Erro ao verificar papel do usuário:', error);
        statusMessage.value = 'Erro ao verificar papel do usuário.';
        setTimeout(() => {
            router.push('/');
        }, 2000);
    }
};

const handleSubmit = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;

        const empresaResponse = await axios.post('https://web-production-8fe56.up.railway.app/empresas', {
            nome: nome.value,
            userId: userId.value,
            perguntas: perguntasFixas.value 
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        const empresaId = empresaResponse.data.id;

        for (const numero of numeros.value) {
            await axios.post('https://web-production-8fe56.up.railway.app/numeros', {
                numero,
                empresaId
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
        }

        statusMessage.value = 'Empresa cadastrada com sucesso!';
        nome.value = '';
        userId.value = '';
        novoNumero.value = '';
        numeros.value = [];
        perguntasFixas.value = []; 
        isEditing.value = false;
        editingEmpresaId.value = '';
        showForm.value = false;
        fetchEmpresas(token);
    } catch (error) {
        console.error('Erro ao processar a solicitação:', error);
        statusMessage.value = 'Erro ao processar a solicitação.';
    }
};

const fetchEmpresas = async (token) => {
    try {
        const response = await axios.get('https://web-production-8fe56.up.railway.app/empresas', {
            headers: { Authorization: `Bearer ${token}` },
        });
        empresas.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar empresas:', error);
        statusMessage.value = 'Erro ao buscar empresas.';
    }
};

const fetchUsers = async (token) => {
    try {
        const response = await axios.get('https://web-production-8fe56.up.railway.app/users', {
            headers: { Authorization: `Bearer ${token}` },
        });
        users.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        statusMessage.value = 'Erro ao buscar usuários.';
    }
};

const fetchPerguntasFixas = async (token) => {
    try {
        const response = await axios.get('https://web-production-8fe56.up.railway.app/perguntas-fixas', {
            headers: { Authorization: `Bearer ${token}` },
        });
        perguntasFixas.value = response.data.map(pergunta => ({
            texto: pergunta.texto,
            resposta: pergunta.resposta
        }));
    } catch (error) {
        console.error('Erro ao buscar perguntas fixas:', error);
        statusMessage.value = 'Erro ao buscar perguntas fixas.';
    }
};

const addNumero = () => {
    if (novoNumero.value) {
        numeros.value.push(novoNumero.value);
        novoNumero.value = '';
    }
};

const removeNumero = (index) => {
    numeros.value.splice(index, 1);
};

const editEmpresa = (empresa) => {
    nome.value = empresa.nome;
    userId.value = empresa.userId;
    isEditing.value = true;
    editingEmpresaId.value = empresa.id;
    showForm.value = true;
};

const confirmDeleteEmpresa = (id) => {
    if (confirm('Tem certeza que deseja deletar esta empresa?')) {
        deleteEmpresa(id);
    }
};

const deleteEmpresa = async (id) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        await axios.delete(`https://web-production-8fe56.up.railway.app/empresas/${id}`, {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        statusMessage.value = 'Empresa deletada com sucesso!';
        fetchEmpresas(user.token);
    } catch (error) {
        console.error('Erro ao deletar empresa:', error);
        statusMessage.value = 'Erro ao deletar empresa.';
    }
};

const filteredEmpresas = computed(() => {
    const term = searchTerm.value.toLowerCase();
    return empresas.value.filter(empresa => empresa.nome.toLowerCase().includes(term));
});

onMounted(() => {
    checkUserRole();
});
</script>



<style scoped>
.empresa-form {
    background-color: #f9fafb;
}

.empresa-form input,
.empresa-form select {
    width: 100%;
}
</style>


<style scoped>
.empresa-form {
    background-color: #f9fafb;
}

.empresa-form input,
.empresa-form select {
    width: 100%;
}
</style>
